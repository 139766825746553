.cco_menu {
    &_a {
        $menu: &;

        display: flex;
        justify-content: center;
        margin-left: -13px;
        margin-right: -13px;

        #{$menu}_item {
            padding-left: 13px;
            padding-right: 13px;
            
            a {
                color: #000;
                display: block;
                font-size: 14px;
                line-height: 1.7;
                padding: 28px 5px 3px;
                position: relative;

                &::before {
                    color: #c67b05;
                    font-family: 'Icomoon';
                    font-size: 20px;
                    font-style: normal;
                    line-height: 1;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &#{$menu}_item_icon {
                &_crane a::before {
                    content: '\e903';
                }

                &_certificate a::before {
                    content: '\e90a';
                }

                &_arrow_up a::before {
                    content: '\e915';
                }

                &_click a::before {
                    content: '\e902';
                }
            }

            @media (max-width: 479px) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        @media (max-width: 479px) {
            justify-content: space-around;
            margin-left: -5px;
            margin-right: -5px;
        }
    }

    &_b {
        $menu: &;

        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;

        #{$menu}_item {
            padding: 5px;

            a {
                background-color: rgba(223, 189, 38, 0.1);
                border-radius: 5px;
                font-family: 'HKGrotesk Medium';
                font-weight: 500;
                font-size: 12px;
                padding: 5px 10px;
            }
        }
    }
}
