.cco_form {
    $form: &;

    label,
    &_label {
        color: #666;
        cursor: default;
        font-family: 'DMSans Medium';
        font-weight: 500;
        line-height: 1.3;
        display: inline-block;
        margin-bottom: 12px;

        small {
            color: #92929d;
            font-family: 'HKGrotesk Regular';
            font-size: 12px;
            font-weight: 400;
        }
    }

    input:not([type='file']):not([type='checkbox']),
    textarea,
    select {
        background-color: #fff;
        color: #666;
        font-size: 16px;
        line-height: 1.3;
        display: block;
        width: 100%;
        min-height: 60px;
        padding: 18px 20px;
        border: 0;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

        &:disabled {
            background-color: #fefcf4;
            cursor: not-allowed;
        }
    }

    select {
        background-image: url('../../assets/icons/Caret_Down_2.svg');
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center right 20px;
        appearance: none;
        cursor: pointer;
        padding-right: 42px;
    }

    button:not(.cco_collapse_toggle) {
        background-color: #c67b05;
        color: #ffffff;
        cursor: pointer;
        font-family: 'DMSans Bold';
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 296px;
        max-width: 100%;
        min-height: 42px;
        padding: 7px 16px;
        border: 2px solid #c67b05;
        border-radius: 21px;
    }

    &_checkbox {
        $checkbox: &;
        display: inline-flex !important;
        align-items: center;
        min-height: 60px;
        padding-left: 80px;
        margin-bottom: 0 !important;
        position: relative;

        &_input {
            visibility: hidden;
            position: absolute;
        }

        &_checkmark {
            background-color: #ffffff;
            color: #c67b1d;
            cursor: pointer;
            font-family: 'Icomoon';
            font-size: 20px;
            font-style: normal;
            line-height: 1;
            speak: never;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            padding: 20px;
            border-radius: 15px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
            position: absolute;
            top: 0;
            left: 0;

            &::before {
                content: '\e920';
                visibility: hidden;
            }
        }

        &_input:checked ~ #{$checkbox}_checkmark::before {
            visibility: visible;
        }

        &_0 {
            min-height: 38px;
            padding-left: 48px;

            #{$checkbox}_checkmark {
                padding: 9px;
                border-radius: 10px;
            }
        }
    }

    &_heading {
        color: #666666;
        font-size: 20px;
        padding-bottom: 20px;
    }

    &_subheading {
        font-family: 'DMSans Medium';
        font-weight: 500;
        padding-bottom: 20px !important;
    }

    &_note {
        font-size: 14px;
        margin-top: 12px;

        a {
            color: #c67b05;
        }

        strong {
            font-family: 'DMSans Medium';
            font-weight: 500;
        }
    }

    &_fieldset {
        display: flex;
        flex-wrap: wrap;
        margin: -12.5px;

        &:nth-last-child(n + 2) {
            margin-bottom: 12.5px;
        }

        &_item {
            width: 100%;
            padding: 12.5px;
            position: relative;

            &_medium {
                width: 50%;
            }
            &_small {
                width: 33.33%;
            }
        }

        &_0 {
            color: #000;
            font-family: 'DMSans Regular';
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
            align-items: center;
            flex-wrap: nowrap;
            margin: 0;

            &:nth-last-child(n + 2) {
                margin-bottom: 15px;
            }

            #{$form}_fieldset_item {
                width: auto;
                padding: 0;

                &:nth-child(n + 2) {
                    padding-left: 20px;
                }

                &_large {
                    flex-grow: 1;
                }
            }
        }

        &_1 {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            #{$form}_fieldset_item {
                &:nth-child(n + 2) {
                    padding-left: 10px;
                }
            }
        }

        &_2 {
            flex-direction: column;
            margin: 0;

            &:nth-last-child(n + 2) {
                margin-bottom: 24px;
            }

            #{$form}_fieldset_item {
                padding: 0;

                &:nth-last-child(n + 2) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &_message {
        line-height: 1.3;

        &:nth-child(n + 2) {
            margin-top: 6px;
        }

        &_success {
            color: #3dd598;
        }
        &_error {
            color: #db3a3a;

            &_footer {
                text-align: right;
                margin-bottom: 20px;
                display: block !important;
            }
        }

        &_has_icon {
            display: flex;

            &::before {
                font-family: 'Icomoon';
                font-style: normal;
                line-height: 14px;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: inline-block;
                min-width: 28px;
                padding-top: 3px;
                padding-right: 8px;
            }

            &#{$form}_message {
                &_success {
                    &::before {
                        content: '\e920';
                        font-size: 19px;
                    }
                }
                &_error {
                    &::before {
                        content: '\e919';
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &_wrap {
        padding: 20px 25px 25px;
        border: 1px solid #e2e2ea;
        border-radius: 23px;
    }

    &_row {
        display: flex;
        flex-wrap: wrap;

        &:nth-last-child(n + 2) {
            margin-bottom: 40px;
        }

        &_0 {
            margin-left: -12.5px;
            margin-right: -12.5px;
        }
    }

    &_col {
        width: 100%;

        &_0 {
            width: calc(50% - 12.5px);
        }

        &_1 {
            width: 50%;
            padding-right: 12.5px;
        }

        &_2 {
            width: 50%;
            padding-left: 12.5px;
        }

        &_3 {
            width: 33.33%;
            padding-left: 12.5px;
            padding-right: 12.5px;
        }
    }

    &_field {
        $field: &;

        &_0 {
            display: flex;

            #{$field}_body {
                position: relative;
            }

            #{$field}_aside {
                flex-grow: 1;
                padding-top: 10px;
                padding-left: 20px;
            }

            #{$field}_label {
                background-color: #fff;
                cursor: pointer;
                display: block;
                height: 185px;
                width: 185px;
                border-radius: 23px;
                overflow: hidden;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
                margin-bottom: 0;
                position: relative;

                &::before {
                    content: '';
                    height: calc(100% - 2px);
                    width: calc(100% - 2px);
                    border: 1px dashed #aeaeae;
                    border-radius: 23px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &_icon {
                    color: #aeaeae;
                    font-family: 'Icomoon';
                    font-size: 56px;
                    font-style: normal;
                    line-height: 1;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: calc(50% - 44px);
                    left: calc(50% - 28px);

                    &_profile::before {
                        content: '\e91a';
                    }
                    &_camera::before {
                        content: '\e92c';
                    }
                    &_file::before {
                        content: '\e92b';
                    }
                }

                &_caption {
                    background-color: rgba(174, 174, 174, 0.5);
                    color: #fff;
                    font-family: 'HKGrotesk Regular';
                    letter-spacing: 0.4px;
                    line-height: 1.5;
                    text-align: center;
                    width: 100%;
                    padding: 4px 10px;
                    border-bottom-left-radius: 23px;
                    border-bottom-right-radius: 23px;
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    &_hover {
                        transform: translateY(100%);
                        transition: all 150ms ease-in-out;
                    }
                }

                &:hover #{$field}_label_caption_hover {
                    transform: translateY(0);
                }
            }

            #{$field}_label_small {
                background-color: #fff;
                cursor: pointer;
                display: block;
                height: 150px;
                width: 150px;
                border-radius: 23px;
                overflow: hidden;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
                margin-bottom: 0;
                position: relative;

                &::before {
                    content: '';
                    height: calc(100% - 2px);
                    width: calc(100% - 2px);
                    border: 1px dashed #aeaeae;
                    border-radius: 23px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &_icon {
                    color: #aeaeae;
                    font-family: 'Icomoon';
                    font-size: 56px;
                    font-style: normal;
                    line-height: 1;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: calc(50% - 44px);
                    left: calc(50% - 28px);

                    &_profile::before {
                        content: '\e91a';
                    }
                    &_camera::before {
                        content: '\e92c';
                    }
                    &_file::before {
                        content: '\e92b';
                    }
                }

                &_caption {
                    background-color: rgba(174, 174, 174, 0.5);
                    color: #fff;
                    font-family: 'HKGrotesk Regular';
                    letter-spacing: 0.4px;
                    line-height: 1.5;
                    text-align: center;
                    width: 100%;
                    padding: 4px 10px;
                    border-bottom-left-radius: 23px;
                    border-bottom-right-radius: 23px;
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    &_hover {
                        transform: translateY(100%);
                        transition: all 150ms ease-in-out;
                    }
                }

                &:hover #{$field}_label_caption_hover {
                    transform: translateY(0);
                }
            }

            #{$field}_input {
                visibility: hidden;
                position: absolute;
            }
        }

        &_1 {
            background-color: #f7f7f7 !important;
            background-image: url('../../assets/icons/Loupe_1.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center left 15px;
            font-size: 14px !important;
            min-height: 0 !important;
            padding: 12px 20px 12px 41px !important;
            box-shadow: none !important;

            &::-webkit-input-placeholder {
                color: #aeaeae;
            }
            &::-moz-placeholder {
                color: #aeaeae;
            }
            &:-ms-input-placeholder {
                color: #aeaeae;
            }
            &:-moz-placeholder {
                color: #aeaeae;
            }
        }
    }

    &_card {
        background-color: #fff;
        padding: 20px 20px 25px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        // &_header {}

        &_body {
            &:nth-child(n + 2) {
                margin-top: 25px;
            }
        }
    }

    &_btn {
        &_0 {
            font-family: 'DMSans Regular' !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 1.3 !important;
            text-align: center !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            width: 100px !important;
            min-height: 26px !important;
            padding: 3px 6px !important;
            border-width: 1px !important;
            border-radius: 13px !important;
        }

        &_1 {
            background-color: transparent !important;
            font-size: 14px !important;
            line-height: 1.3 !important;
            display: flex !important;
            width: auto !important;
            min-height: 26px !important;
            padding: 4px 7px !important;
            border: 0 !important;
            border-radius: 0 !important;

            &::before {
                color: #db3a3a;
                content: '\e919';
                font-family: 'Icomoon';
                font-style: normal;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &_2 {
            margin-top: 24px;

            a {
                background-color: #c67b05;
                color: #ffffff;
                font-family: 'DMSans Bold';
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 40px;
                padding: 7px 16px;
                border: 2px solid #c67b05;
                border-radius: 21px;
            }
        }
    }

    &_blurb {
        &_0 {
            font-size: 14px;
            display: flex;
            justify-content: center;
            width: 100px;
            max-width: 100%;

            &::after {
                color: #3dd598;
                content: '\e920';
                font-family: 'Icomoon';
                font-size: 17px;
                font-style: normal;
                line-height: 14px;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                min-width: 17px;
                padding-top: 2px;
                padding-left: 8px;
            }
        }
    }

    &_img {
        background-color: #fff;
        object-fit: cover;
        display: block;
        height: 185px;
        width: 185px;
        border-radius: 23px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);
    }

    @media (max-width: 1199px) {
        button:not(.cco_collapse_toggle) {
            font-size: 16px;
        }
    }

    @media (max-width: 980px) {
        &_col {
            &_0,
            &_1,
            &_2 {
                width: 100%;
            }
            &_1 {
                padding-right: 0;
                margin-bottom: 25px;
            }
            &_2 {
                padding-left: 0;
            }
            &_3 {
                width: 100%;

                &:nth-last-child(n + 2) {
                    margin-bottom: 25px;
                }
            }
        }

        &_field {
            $field: &;

            &_0 {
                #{$field}_label_caption_hover {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 767px) {
        button:not(.cco_collapse_toggle) {
            width: 100%;
        }

        &_fieldset {
            &_item {
                &_medium,
                &_small {
                    width: 100%;
                }
            }

            &_0 {
                font-size: 14px;
            }
        }

        &_field {
            $field: &;

            &_0 {
                #{$field}_label {
                    height: 140px;
                    width: 140px;

                    &,
                    &:before {
                        border-radius: 15px;
                    }

                    &_icon {
                        font-size: 48px;
                        top: calc(50% - 40px);
                        left: calc(50% - 24px);
                    }

                    &_caption {
                        font-size: 14px;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
                    }
                }
            }
        }

        &_img {
            height: 140px;
            width: 140px;
        }
    }
}


