.cco_card {
    $card: &;

    &_0 {
        background-color: #fff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_body {
            display: flex;
        }

        #{$card}_aside {
            width: 100px;
        }

        #{$card}_main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 84px);
            padding-left: 40px;
        }

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }

    &_1 {
        background-color: #fff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }

    &_2 {
        background-color: #fff;
        padding: 25px 40px 30px;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_body {
            display: flex;
        }

        #{$card}_aside {
            width: 100px;
        }

        #{$card}_main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 84px);
            padding-left: 40px;
            flex: 1;
        }

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }

        @media (max-width: 767px) {
            padding-left: 30px;
            padding-right: 30px;
            
            #{$card}_main  {
                flex-basis: 40%; 
              }
        }
    }

    &_myCredential {
        background-color: #fff;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_body {
            display: flex;
        }

        #{$card}_aside {
            width: 100px;
        }

        #{$card}_main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 84px);
            padding-left: 40px;
        }

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }

      &_myCredential {
        background-color: #fff;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_body {
            display: flex;
        }

        #{$card}_aside {
            width: 100px;
        }

        #{$card}_main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 84px);
            padding-left: 40px;
        }

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }

    &_myCertification {
        background-color: #fff;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

        #{$card}_body {
            display: flex;
        }

        #{$card}_aside {
            width: 100px;
        }

        #{$card}_main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 84px);
            padding-left: 40px;
        }

        #{$card}_footer {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }
}
