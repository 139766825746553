.et-social-youtube a.icon:before {
    content: "\e0a3";
}

.et-social-facebook a.icon:before {
    content: "\e0aa";
}

.et-social-linkedin a.icon:before {
    content: "\e0cb";
}

.et-social-twitter a.icon:before {
    content: "\e0cb";
}

#main-footer {
    background-color: #222222;
}

#footer-bottom {
    background-color: #ffffff;
    padding: 15px 0 5px;
}

.container {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    text-align: left;
    position: relative;
}
@media (max-width: 768px) {


   .et-social-icons {
        margin-right: 60px;
    }
}

.et-social-icons {
    float: right;
}

.et-social-icons ul {
    list-style: none;
}

.et-social-icons li {
    display: inline-block;
    margin-left: 20px;
}

#footer-bottom a {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.et-social-icon a {
    display: inline-block;
    font-size: 24px;
    position: relative;
    text-align: center;
    -webkit-transition: color 300ms ease 0s;
    transition: color 300ms ease 0s;
    color: #666;
    text-decoration: none;
}

a:hover {
    color: #c67b05;
}

#footer-info {
    text-align: left;
    color: #666;
    padding-bottom: 10px;
    float: left;
    padding-left: 5%;
}

.et-social-icon span {
    display: none;
}


















