.cco_accordion {
    &:nth-last-child(n + 2) {
        margin-bottom: 25px;
    }

    &_item {
        $item: &;
        background-color: #fff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

        &:nth-last-child(n + 2) {
            margin-bottom: 25px;
        }

        &_header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 50px;
            padding-right: 140px;
            position: relative;
        }

        &_toggle {
            background-color: #c67b05;
            color: #ffffff;
            cursor: pointer;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100px;
            min-height: 42px;
            padding: 7px 16px;
            border: 2px solid #c67b05;
            border-radius: 21px;
            position: absolute;
            top: 4px;
            right: 0;
        }

        &_header_custom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 50px;
            padding-right: 40px;
            position: relative;
        }

        &_toggle_custom {

            // background-color: transparent;
            //     color: #c67b05;
            //     font-size: 14px;
            //     font-weight: 400;
            //     line-height: 1;
            //     width: auto;
            //     min-height: 0;
            //     padding: 7px;
            //     border: 0;
            //     border-radius: 0;
            //     top: -2px;
            //     right: -5px;


            background-color:transparent;
            color: #c67b05;
            cursor: pointer;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: auto;
            min-height: 42px;
            padding: 7px;
            border: 0;
            border-radius: 21px;
            position: absolute;
            top: 4px;
            right: 0;

            &_text_custom {
                display: none;
            }
        }

        &_toggle_custom::before {
            /* Your existing icon styles */
            content: '\e911';
            font-family: 'Icomoon';
            font-style: normal;
            speak: never;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            /* Adjust icon size and position as needed */
            font-size: 16px;
            /* Adjust the size */
            margin-right: 5px;
            /* Add margin if needed */


        }

        &_body {
            display: none;

            &_inner {
                padding-top: 20px;
                border-top: 1px solid #e4e4e2;
                margin-top: 20px;
            }
        }

        &.active #{$item} {
            &_toggle::before {
                transform: scale(-1);
            }

            &_body {
                display: block;
            }
        }
    }

    @media (max-width: 1199px) {
        &_item_toggle {
            font-size: 16px;
        }
    }

    @media (max-width: 767px) {
        &_item {
            &_header {
                min-height: 24px;
                padding-right: 43px;
            }

            &_toggle {
                background-color: transparent;
                color: #c67b05;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
            min-height: 42px !important;
                width: auto;
                min-height: 0;
                padding: 7px;
                border: 0;
                border-radius: 0;
                top: -2px;
                right: -5px;

                &::before {
                    content: '\e911';
                    font-family: 'Icomoon';
                    font-style: normal;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                &_text {
                    display: none;
                }
            }
        }
    }
}