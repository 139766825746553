.cco_form_field_body_p {
    display: flex; /* Use flexbox to create a row */
    align-items: center; /* Align items vertically in the center */
    justify-content: flex-start; /* Align items horizontally to the left */
  }
  
  /* Add spacing between the icons */
  .cco_form_field_label_small {
    margin-right: 10px; /* Adjust as needed */
  }
  
  /* Hide the newline character inside the label elements */
  .cco_form_field_label_small > span {
    white-space: nowrap;
  }


  .cropper-face{
     opacity: 0.8 !important;
    

  }

  .cropper-face{
    background: url('../../assets/images/mask_uk.png') no-repeat center !important;
    max-width: 307.94px;
    max-height: 390px;
    position: relative;
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    box-sizing: border-box;
    transform: matrix(0.75, 0, 0, 0.75, -13.15, -14.08);
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transition: none 0s ease 0s;
opacity: 0.8 !important;
  }
  @media screen and (max-width: 767px) {
    .cropper-face {
        background-image: none !important;
        opacity: 1 !important;
    }
}



  .no-mask .cropper-face {
    background: none !important; /* or background: transparent !important; */
  }

  .cropper-view-box{
    opacity: 0.8 !important;
  }


  .webCam video{
    border-radius: 20px;
    border: 2px dashed #c67b05;
    padding: 2px;
  }

  .cropper-drag-box{
    border-radius: 10px;
  }