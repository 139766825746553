.custom-overlay {
    position: fixed;
    z-index: 100; 
  
    width: 100vw;
    height: 100%;
    display: block;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    pointer-events: none;
}
@media (max-width: 768px) {
    .custom-overlay  {
        z-index: 1; 
       
      
    }
}
@media (max-width: 1199px) {
    .custom-overlay  {
        z-index: 1; 
      
    }
}

.bannerVisible{
    max-width: 1350px;
    padding: 80px 40px 17.5px;
    margin-left: 250px;
    margin-right: auto;

    @media (min-width: 1851px) {
        margin-left: auto;
    }
    @media (max-width: 1199px) {
        margin-left: auto;
    }
    @media (max-width: 767px) {
        padding-bottom: 15px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.cco_text {
    &_inner > *:last-child {
        padding-bottom: 0;
    }
    &_red {
        background-color: #FF0000;
        color: #fff;
        font-size: 16px;
        padding: 15px 25px;
        border: 1px solid #FF0000;
        margin-bottom: 40px;
    }

    &_orange {
        background-color: #FF6600;
        color: #fff;
        font-size: 16px;
        padding: 15px 25px;
        border: 1px solid #FF6600;
        margin-bottom: 40px;
    }

    &_green {
        background-color: #008000;
        color: #fff;
        font-size: 16px;
        padding: 15px 25px;
        border: 1px solid #008000;
        margin-bottom: 40px;
    }

    &_yellow {
        background-color: #ffff00;
        color: #fff;
        font-size: 16px;
        padding: 15px 25px;
        border: 1px solid #ffff00;
        margin-bottom: 40px;
    }
}


