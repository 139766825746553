.cco_section {
    &_0 {
        max-width: 1350px;
        padding: 40px 40px 60px;
        margin-left: 250px;
        margin-right: auto;

        @media (min-width: 1851px) {
            margin-left: auto;
        }
        @media (max-width: 1199px) {
            margin-left: auto;
        }
        @media (max-width: 767px) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &_1 {
        background-color: #ffffff;
        padding: 15px 40px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 110;

        @media (max-width: 767px) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &_2 {
        background-color: #ffffff;
        padding: 5px 40px;
        border-bottom: 1px solid #e2e2ea;
        position: relative;
        z-index: 100;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &_3 {
        max-width: 1350px;
        padding: 40px 40px 17.5px;
        margin-left: 250px;
        margin-right: auto;

        @media (min-width: 1851px) {
            margin-left: auto;
        }
        @media (max-width: 1199px) {
            margin-left: auto;
        }
        @media (max-width: 767px) {
            padding-bottom: 15px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &_4 {
        max-width: 1350px;
        padding: 17.5px 40px 40px;
        margin-left: 250px;
        margin-right: auto;

        @media (min-width: 1851px) {
            margin-left: auto;
        }
        @media (max-width: 1199px) {
            margin-left: auto;
        }
        @media (max-width: 767px) {
            padding-top: 15px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}
