.et_builder_inner_content {
    // Sections
    .cco_et_section_0 {
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;

        > .et_pb_row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1360px;
            min-height: 100vh;
            padding-top: 150px;
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 0;
            position: static;
        }

        @media (min-width: 981px) and (max-width: 1199px) {
            > .et_pb_row {
                padding-top: 180px;
            }
        }

        @media (max-width: 767px) {
            > .et_pb_row {
                padding-top: 130px;
            }
        }
    }

    // Rows
    .cco_et_row_0 {
        padding-top: 0;
        padding-bottom: 40px;
    }
    .cco_et_row_1 {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: -12.5px;
        margin-right: -12.5px;
    }

    // Cols
    .cco_et_col_0 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding-top: 50px;
        padding-bottom: 70px;
        padding-right: 2.75%;
        margin-right: 0;
        z-index: 100;

        @media (max-width: 1199px) {
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            margin-bottom: 20px;
        }

        @media (max-width: 980px) {
            display: block;
        }
    }
    .cco_et_col_1 {
        width: 50%;
        padding-top: 0;
        padding-bottom: 0;
        position: static;

        @media (max-width: 1199px) {
            width: 100%;
            margin-top: auto;
        }
    }
    .cco_et_col_2,
    .cco_et_col_3 {
        width: auto !important;
        padding-left: 12.5px;
        padding-right: 12.5px;
        margin-bottom: 30px !important;
        margin-right: 0 !important;
    }

    // Texts
    .cco_et_text_0 {
        &,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff !important;
        }

        h1 {
            font-size: 72px;
        }
        h2 {
            font-size: 20px;
            line-height: 1.5 !important;
        }

        .et_pb_text_inner > {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding-bottom: 30px;
            }
        }

        @media (max-width: 980px) {
            h1 {
                font-size: 56px;
            }
        }
        @media (max-width: 767px) {
            h1 {
                font-size: 40px;
            }
        }
    }

    // Imgs
    .cco_et_img_0 {
        .et_pb_image_wrap,
        img {
            width: 100%;
        }

        @media (min-width: 1200px) {
            height: 100%;
            width: 50%;
            position: absolute;
            top: 50px;
            left: 50%;

            .et_pb_image_wrap {
                height: 100%;

                img {
                    object-fit: contain;
                    height: 100%;
                    max-width: 1280px;
                }
            }
        }

        @media (max-width: 1199px) {
            margin-left: -40px;
            margin-right: -40px;
        }
    }
}
