body {
    font-weight: 400 !important;

    &.overflow-hidden {
        overflow: hidden;
    }
}

img {
    vertical-align: middle;
}

#page-container {
    overflow: hidden;
    margin-top: 0 !important;
}

#main-content .container::before,
#sidebar {
    display: none;
}

#left-area {
    padding: 0px 0px 58px !important;
}

@media (min-width: 981px) {
    #left-area {
        float: none !important;
        width: 100% !important;
    }
}

// BlockUI CSS Start
.loading-indicator {
    text-align: center;
}

.block-ui {
    position: relative;
    min-height: 3em;
}

.block-ui-container {
    position: absolute;
    z-index: 1010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 2em;
    overflow: hidden !important;
}

.block-ui-container:focus {
    outline: none;
}

.block-ui-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.75;
    filter: alpha(opacity=50);
    background-color: rgb(184, 184, 184);
}

.block-ui-message-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    z-index: 10001;
}

.block-ui-message {
    color: #333;
    background: none;
    z-index: 1011;
    margin-top: 9%;
}

#indicator {
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    animation: spin 1s linear infinite;
}

#circle {
    fill: none;
    stroke: #c67b05;
    stroke-linecap: round;
    stroke-width: 4;
    animation: draw 3s ease-in-out infinite;
}

@keyframes draw {
    0% {
        stroke-dasharray: 20, 282.6;
    }

    50% {
        stroke-dasharray: 200, 282.6;
    }

    100% {
        stroke-dasharray: 20, 282.6;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

//BlockUI CSS END

//HostedForm CSS
// .hostedformButtonStyle {
//     background-color: #c67b05;
//     color: #ffffff;
//     font-family: 'DMSans Bold';
//     font-size: '18px';
//     font-weight: '700';
//     line-height: 1;
//     text-align: center;
//     display: 'inline-flex';
//     flex-direction: 'column';
//     align-items: 'center';
//     justify-content: 'center';
//     width: '200px';
//     max-width: '100%';
//     padding: '7px 16px';
//     border: 'none';/
// }

// .hostedformContainerStyle {
//     background-color: #c67b05;
//     color: #ffffff;
//     font-family: 'DM Sans Bold';
//     font-size: '18px';
//     font-weight: '700';
//     line-height: 1.3;
//     text-align: 'center';
//     display: 'inline-flex';
//     flex-direction: 'column';
//     align-items: 'center';
//     justify-content: 'center';
//     width: '296px';
//     max-width: '100%';
//     min-height: '42px';
//     padding: '7px 16px';
//     border: '2px solid #c67b05';
//     border-radius: '21px';
// }

.ccolinks {
    color: rgb(198, 123, 5) !important;

    align-items: center !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    margin: 0px !important;
    font-size: 12px !important;
    display: inline-flex !important;
    line-height: 0px !important;
    height: 0px !important;
    font-weight: bolder;
}

.custom-post-breadcrumb .lms-breadcrumbs {

    &,
    .breadcrumb-trail {
        display: inline;

        >span:nth-child(-n + 2) {
            display: none;
        }
    }
}

.radio-button-item {
    display: grid;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    box-shadow: none !important;

}

//   @media (max-width: 767px) {
//     .radio-button-item {
//         display: grid;
//     }
// }

.description {
    margin-right: 10px;

}

.radio-buttons input:not([type=file]):not([type=checkbox]) {
    display: flex;
    box-shadow: none !important;
    transform: scale(1.1);
}

.radio-buttons-yes {
    margin-right: 10px;
    text-align: center !important;
}

.radio-buttons-no {
    margin-left: 10px;
    text-align: center !important;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modal-content {
    background-color: #fff;
    padding: 50px;
    border-radius: 5px;
    max-width: 800px;
    width: 400px;
    text-align: center;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content p {
    margin-bottom: 0;
}

.close {
    position: absolute;
    float: right;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    /* Adjust the font size as needed */
    font-weight: bold;
    /* Adjust the font weight as needed */
}

.close:hover {
    color: red;
    /* Adjust the color of the close icon on hover if desired */
}

.paymentTimer {
    font-size: 24px;
    color: #c67b05;
    background-color: #fff;
    padding: 10px !important;
    border-radius: 10px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.05);
    text-align: center;
  }



  .cco_card.cco_card_myCredential {
    position: relative;
}
.cco_card.cco_card_myCertification {
  position: relative;
}


.cco_card.cco_card_myCredential:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black overlay */
    opacity: 0;
    transition: opacity 0.8s ease;
    border-radius: 15px;
    cursor: pointer;
}


.cco_card.cco_card_myCredential:hover .cco_card_body,
.cco_card.cco_card_myCredential:hover .cco_card_footer {
    transform: scale(1.05); /* Increase the size of the card on hover */
}

.cco_card.cco_card_myCredential:hover::after,
.cco_card.cco_card_myCredential:hover .cco_card_body,
.cco_card.cco_card_myCredential:hover .cco_card_footer {
    opacity: 1;
}

.cco_card.cco_card_myCredential  .cco_card.cco_card_myCertification .cco_card_footer a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.ac_card_hover:hover{
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: scale(1.05); /* Increase the size of the card on hover */
}


.cco_menu.cco_menu_0 li.cco_menu_item.cco_menu_item_primary a {
    font-size: 15px;
  }
  
  .cco_menu.cco_menu_0 li.cco_menu_item.cco_menu_item_primary:nth-child(4) a {
    font-size: 15px;
  }

  .cjButton{
    border-radius: 0px !important;
    padding: 12px 0px 12px 0px !important;
    margin: 20px 0px 20px 0px !important;
    transition: transform 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
 

  }

  .cjButton:hover{
    border: #ffffff 2px double !important;
    box-shadow: 0 10px 30px rgb(0 0 0 / 5%) !important;

  }

  .animated-element {
    position: relative;
    animation: slideFromLeft 1s ease-in-out forwards;
    transform-origin:  center; /* Set the transform origin to start from the center */
  }
  
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(0);
    }
  }


  // Tree 

  .tree-container {
    width: 100%;
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add additional styles for responsiveness */
  @media (max-width: 768px) {
    .tree-container {
      height: 60vh;
      /* Additional styles for smaller screens */
    }
  }

  /* custom-tree.css */

.node__root > circle {
  fill: #c67b05;
  stroke: #c67b05 !important;
  r:25;
}

.node__branch > circle {
  fill: #c67b05;
  stroke: #c67b05 !important;
  r:25;

}

.node__leaf > circle {
  fill: #c67b05;
  stroke: #c67b05 !important;
  r:25;

}

.rd3t-link{
  stroke: #c67b05 !important;
}


// .cco_group_item::after {
//   content: '\2192'; /* Right arrow symbol */
//   font-size: 16px; /* Adjust the size as needed */
//   margin-left: 5px; /* Adjust the spacing between the text and the arrow */

// }

.pending-card{
  background-color: rgba(255, 166, 0, 0.46) !important;
}

.purchased-card{
  background-color: rgba(106, 255, 0, 0.46) !important;
}

.notcompleted-card{
  background-color: rgba(255, 0, 0, 0.34) !important;
}

.customHover{
  transition: transform 0.5s ease; 
}

.customHover:hover{
  background-color: rgba(198, 123, 5, 1) !important;
  color: #ffffff !important;
  cursor: pointer;
  transform: scale(1.05); /* Increase the size of the card on hover */
}


.hover-list li {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hover-list li:hover {
  background-color: #ddd7d7; /* Change this to the desired hover background color */
}



// .tabContainer{
//   margin-bottom: 10px;
//   margin-top: -39px;
//   margin-left: -45px;
// }


// .tab-bar{
//   height: 40px;
//   background: transparent;
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-start;
//   transform: all 0.5s ease;
//   margin-left: 10px;
//   padding: 20px;
// }

// .tab{
//   height: 40px;
//   background-color:silver;
//   box-shadow: 2px 0px 0px rgba(0,0,0,0.1);
//   padding-left: 20px;
//   padding-right: 20px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   z-index: 1;
//   // margin-right: 6px;
//   border-radius: 5px 5px 0 0;

//   transform: all 0.5s ease;
//   appearance: none;
//   outline: none;
//   border: none;
//   cursor: pointer;

//   font-size: 16px;
// font-weight: normal;

// border-top-left-radius: 30px;
// border-top-right-radius: 30px;

// }

// .selected{
// background:#fafafb;  
// z-index: 100;
// transform: all 0.5s ease;
// font-size: 18px;
// font-weight: bold;
// color: black;
// }

// .tab.selected:hover{
//   background-color: whitesmoke;
// }

// .tab:hover{
//   background-color: whitesmoke;

// z-index: 1111;
// }

// @media (max-width: 768px) {
//   .tab-bar {
//     height: 40px;
//     background: transparent;
//     margin-left: 10px;
//     padding: 10px;  
//   }

//   .tab {
//     padding-left: 15px;
//     padding-right: 15px;
//     font-size: 14px;
//     font-weight: normal;
//   }
// }


.cco_accordion_item_toggle_1{
  background-color:transparent;
}

.btn-disabled{
  box-shadow: none;
  background-color: darkgrey !important;
  border: darkgray 1px solid !important;
  color: #fff;
  cursor: not-allowed !important;
}
  
  




  



  
