.cco_img {
    $img: &;

    &_disabled {
        opacity: 0.5;
    }

    &_0 {
        img {
            object-fit: contain;
            vertical-align: middle;
            height: 40px;
        }
    }

    &_1 {
        background-color: #efece8;
        height: 180px;
        width: 180px;
        min-width: 180px;
        border: 8px solid #fafafb;
        border-radius: 30px;
        overflow: hidden;
        margin-top: -80px;
        position: relative;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        #{$img}_wrap {
            display: block;
            height: 100%;
        }

        #{$img}_icon {
            background-color: #fff;
            font-family: 'Icomoon';
            font-size: 40px;
            font-style: normal;
            line-height: 1;
            speak: never;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            padding: 6px;
            border-radius: 10px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: calc(50% - 42px);
            left: calc(50% - 26px);

            &_path_0::before {
                content: '\e91b';
            }
            &_path_1::before {
                content: '\e91c';
                margin-left: -1em;
            }
            &_path_2::before {
                color: #fff;
                content: '\e91d';
                margin-left: -1em;
            }
        }

        #{$img}_caption {
            background-color: rgba(174, 174, 174, 0.5);
            color: #fff;
            font-family: 'HKGrotesk Regular';
            letter-spacing: 0.4px;
            text-align: center;
            width: 100%;
            padding: 4px 10px;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            position: absolute;
            bottom: 0;
            left: 0;

            &_hover {
                transform: translateY(100%);
                transition: all 150ms ease-in-out;
            }
        }

        #{$img}_caption_pending {
            background-color: rgb(238, 160, 16);
            color: #fff;
            font-family: 'HKGrotesk Regular';
            letter-spacing: 0.4px;
            text-align: center;
            width: 100%;
            padding: 4px 10px;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            position: absolute;
            bottom: 0;
            left: 0;

            &_hover {
                transform: translateY(100%);
                transition: all 150ms ease-in-out;
            }
        }

        #{$img}_caption_verified {
            background-color: rgb(41, 174, 12);
            color: #fff;
            font-family: 'HKGrotesk Regular';
            letter-spacing: 0.4px;
            text-align: center;
            width: 100%;
            padding: 4px 10px;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            position: absolute;
            bottom: 0;
            left: 0;

            &_hover {
                transform: translateY(100%);
                transition: all 150ms ease-in-out;
            }
        }

        #{$img}_caption_rejected {
            background-color: rgb(255, 0, 0);
            color: #fff;
            font-family: 'HKGrotesk Regular';
            letter-spacing: 0.4px;
            text-align: center;
            width: 100%;
            padding: 4px 10px;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            position: absolute;
            bottom: 0;
            left: 0;

            &_hover {
                transform: translateY(100%);
                transition: all 150ms ease-in-out;
            }
        }

        &:hover #{$img}_caption_hover {
            transform: translateY(0);
        }

        @media (max-width: 980px) {
            margin-top: 0;

            #{$img}_caption_hover {
                display: none;
            }
        }

        @media (max-width: 767px) {
            height: 140px;
            width: 140px;
            min-width: 140px;
            border-width: 5px;
            border-radius: 15px;

            #{$img}_icon {
                font-size: 32px;
                top: calc(50% - 38px);
                left: calc(50% - 22px);
            }

            #{$img}_caption {
                font-size: 14px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}
