.cco_row {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    &_0 {
        background-color: #ffffff;
        display: flex;
        padding: 25px 40px 30px;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        margin-top: 80px;

        @media (max-width: 980px) {
            margin-top: 0;
        }

        @media (max-width: 767px) {
            background-color: transparent;
            display: block;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &_1 {
        @media (min-width: 768px) {
            margin-bottom: 35px;
        }
    }

    &_2 {
        margin-bottom: 20px;
    }

    &_3 {
        padding: 20px 25px 25px;
        border: 1px solid #e2e2ea;
        border-radius: 23px;

        @media (min-width: 768px) {
            margin-bottom: 35px;
        }
    }

    &_4 {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &_5 {
        display: flex;
        margin-bottom: 0;
    }

    &_6 {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 25px 25px;
        border: 1px solid #e2e2ea;
        border-radius: 23px;

        @media (min-width: 768px) {
            margin-bottom: 35px;
        }
    }

    &_7 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    &_8 {
        margin-bottom: 40px;
    }

    &_9 {
        padding: 20px 25px 25px;
        border: 1px solid #e2e2ea;
        border-radius: 23px;
        margin-bottom: 40px;
    }

    &_10 {
        padding: 20px 25px 25px;
        border: 1px solid #e2e2ea;
        border-radius: 23px;
        margin-bottom: 20px;
    }

    &_11 {
        background-color: #ffffff;
        padding: 25px 40px 30px;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

        @media (max-width: 767px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (min-width: 768px) {
            margin-bottom: 35px;
        }
    }
}
