.cco_table {
    $table: &;
    font-size: 14px;
    line-height: 1.6;
    display: block;

    &_body {
        display: block;
    }

    &_row {
        display: flex;
        margin-left: -20px;
        margin-right: -20px;
    }

    &_cell {
        width: 33.33%;
        padding: 0 20px;

        &_small {
            width: 30%;
        }

        &_medium {
            width: 40%;
        }

        &_large {
            width: 66.66%;
        }
    }

    @media (max-width: 980px) {
        &_row {
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
        }

        &_cell {
            width: 100%;
            padding-left: 0;
            padding-right: 0;

            &_large {
                width: 100%;
            }
        }
    }

    &_0 {
        #{$table}_row {
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
        }

        #{$table}_cell {
            width: 100%;
            padding-left: 0;
            padding-right: 0;

            &:nth-last-child(n + 2) {
                margin-bottom: 22px;
            }

            &_large {
                width: 100%;
            }
        }
    }

    &_1 {
        &:nth-last-child(n + 2) {
            padding-bottom: 20px;
            border-bottom: 1px solid #e2e2ea;
            margin-bottom: 20px;
        }
    }
}
