.cco_content_area {
    &_0 {
        min-height: 100vh;
        padding-top: 70px;

        @media (min-width: 768px) {
            background-color: #fafafb;
        }
    }
}
