.cco_form {
    &_a {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        input[type='text'] {
            background-color: #fff;
            border: 0;
            border-radius: 15px;
            color: #666;
            font-size: 14px;
            line-height: 1.7;
            position: relative;
            width: 100%;
            padding: 13px 70px 13px 140px;

            ::-webkit-input-placeholder {
                color: #666;
                font-size: 14px;
                line-height: 1.7;
            }

            :-ms-input-placeholder {
                color: #666;
                font-size: 14px;
                line-height: 1.7;
            }

            ::placeholder {
                color: #666;
                font-size: 14px;
                line-height: 1.7;
            }
        }

        select {
            background-color: #f7f7f7;
            border: 0;
            border-radius: 7px;
            color: #666;
            font-size: 12px;
            line-height: 1.3;
            padding: 6px 12px 6px 8px;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 115px;
            z-index: 100;
        }

        &_icon_search {
            background-color: #c67b05;
            border: 0;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            padding: 5px;
            width: 44px;
            height: 40px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);

            span {
                display: none;
            }

            &::before {
                font-family: 'Icomoon' !important;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                line-height: 1;
                speak: never;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: '\e914';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 24px;
            }
        }

        @media (max-width: 767px) {
            input[type='text'] {
                padding-left: 25px;
            }

            select {
                background-color: #fff;
                position: static;
                margin-top: 25px;
                padding-left: 25px;
                padding-right: 25px;
                width: 100%;
            }

            &_icon_search {
                top: 5px;
                transform: translateY(0);
            }
        }
    }
}
